
/*
 * Backend Colors
 */

/* Links */

a,
.uk-link {
	/*
  color: #569aca;
	*/
	color: #659f13;
}

a:hover,
.uk-link:hover {
  /*
	color: #417396;
	*/
	color: #548510;
}

/* Blocks & boxes */

.uk-panel-box-primary {
  /*
	background-color: #569aca;
  */
	background-color: #ffef2a;
}

.uk-block-primary {
  /*
	background: #569aca;
	*/
	background: #ffef2a;
}

/* Articles */

.be-header h2
{
	/*
	color: #569aca;
	*/
	color: #659f13;
}

.uk-article-lead {
	/*
	color: #569aca;
	*/
	color: #659f13;
}

.uk-comment-primary .uk-comment-header {
	/*
	color: #569aca;
	*/
	color: #659f13;
}

/* Navigation */

.be-sidebar-wrapper,
.be-sidebar-wrapper::after
{
	background: #101010;
}

.be-sidebar-subnav .uk-accordion > h3.uk-accordion-title::after
{
	background: #101010;
}

.uk-nav-side > li.uk-active > a {
  /*
	background: #569aca;
	*/
	background: #659f13;
}

.uk-nav-side ul a {
  /*
	color: #569aca;
	*/
  color: #659f13;
}

.uk-nav-side ul a:hover {
  /*
	color: #417396;
	*/
  color: #548510;
}

.uk-nav-dropdown > li > a:hover,
.uk-nav-dropdown > li > a:focus {
  /*
	background: #569aca;
  */
	background: #659f13;
}

.uk-nav-dropdown ul a {
  /*
	color: #569aca;
	*/
  color: #659f13;
}

.uk-nav-dropdown ul a:hover {
  /*
	color: #417396;
	*/
  color: #548510;
}

/*
.uk-navbar-nav > li > a
{
	color: #ffffff;
	border-color: #494b4d;
}
*/


.uk-navbar-nav > li > a
{
	color: #ffffff;
	border-color: #494b4d;
}

.uk-nav-navbar > li > a:hover,
.uk-nav-navbar > li > a:focus {
	/*
  background: #569aca;
	*/
	background: #ffef2a;
	color: #000000;
}

.uk-navbar-nav > li.uk-open > a,
.uk-navbar-nav > li.uk-active > a
{
	background: #ffef2a;
	color: #000000;
}

.uk-nav-navbar ul a {
	/*
  color: #569aca;
  */
	color: #659f13;
}

.uk-nav-navbar ul a:hover {
  /*
	color: #417396;
	*/
  color: #548510;
}

/*
.uk-navbar-nav > li > a:active {
  background-color: #569aca;
}
*/

.uk-navbar-nav > li > a:active,
.uk-navbar-nav > li:hover > a,
.uk-navbar-nav > li > a.focus,
.uk-navbar-nav > li.uk-open > a,
.uk-navbar-nav > li.uk-active > a
{
	background-color: #ffef2a;
	color: #000000;
}

.uk-navbar-content > a:not([class]) {
	/*
  color: #569aca;
  */
	color: #659f13;
}

.uk-navbar-content > a:not([class]):hover {
  /*
	color: #417396;
	*/
  color: #548510;
}

.uk-subnav > * > :hover,
.uk-subnav > * > :focus {
	/*
  color: #569aca;
  */
	color: #659f13;
}

.uk-subnav > .uk-active > * {
	/*
  color: #569aca;
  */
	color: #659f13;
}

.uk-subnav-pill > .uk-active > * {
 	/*
	background: #569aca;
  */
	background: #659f13;
}

.uk-nav-search > li.uk-active > a {
 	/*
	background: #569aca;
  */
	background: #659f13;
}

.uk-nav-search ul a {
	/*
  color: #569aca;
  */
	color: #659f13;
}

.uk-nav-search ul a:hover {
  /*
	color: #417396;
	*/
  color: #548510;
}

/* Pagination */

.uk-pagination > li > a:hover,
.uk-pagination > li > a:focus {
	/*
  background-color: #62ade3;
	*/
  background-color: #ffef2a;
  border-color: #494b4d;
  color: #494b4d;
}

.uk-pagination > li > a:active {
  /*
  background-color: #62ade3;
  */
	border-color: #101010;
	background-color: #101010;
}

.uk-pagination > .uk-active > span {
  /*
	background: #569aca;
	*/
	background: #659f13;
}

/* Tables */

.uk-table th {
	background: #101010;
}

.uk-table-hover tbody tr:hover {
  background: #fdf7a8;
}

/* Tabs */

.uk-tab > li > a:hover,
.uk-tab > li > a:focus,
.uk-tab > li.uk-open > a {
  /*
	background: #62ade3;
  */
	border-color: #494b4d;
	background: #ffef2a;
}

.uk-tab > li.uk-active > a {
  /*
	border-color: #569aca;
  background: #569aca;
  */
	border-color: #101010;
  background: #101010;
}

.uk-tab-bottom > li.uk-active > a {
  /*
	border-bottom-color: #569aca;
	*/
	border-bottom-color: #659f13;
}

@media (min-width: 768px) {
  .uk-tab-left > li.uk-active > a,
  .uk-tab-right > li.uk-active > a {
    /*
		border-color: #569aca;
		*/
		border-color: #659f13;
  }
}

/* Forms */

.uk-form select:focus,
.uk-form textarea:focus,
.uk-form input:not([type]):focus,
.uk-form input[type="text"]:focus,
.uk-form input[type="password"]:focus,
.uk-form input[type="datetime"]:focus,
.uk-form input[type="datetime-local"]:focus,
.uk-form input[type="date"]:focus,
.uk-form input[type="month"]:focus,
.uk-form input[type="time"]:focus,
.uk-form input[type="week"]:focus,
.uk-form input[type="number"]:focus,
.uk-form input[type="email"]:focus,
.uk-form input[type="url"]:focus,
.uk-form input[type="search"]:focus,
.uk-form input[type="tel"]:focus,
.uk-form input[type="color"]:focus {
  /*
	border-color: #569aca;
  */
	border-color: #659f13;
}

.uk-form-password-toggle:hover {
	/*
  color: #417396;
	*/
	color: #659f13;
}

.uk-form input[type=radio]:checked:before {
  /*
	background: #569aca;
	*/
	background: #659f13;
}

.uk-form input[type=checkbox]:checked:before,
.uk-form input[type=checkbox]:indeterminate:before {
  /*
	color: #569aca;
	*/
	color: #659f13;
}

.be-required label
{
	/*
	color: #569aca;
	*/
	color: #dd0055;
}

/* Buttons */

.uk-button {
	background : #101010;
}

.uk-button:hover,
.uk-button:focus {
	/*
  background-color: #62ade3;
	*/
  background-color: #ffef2a;
  border-color: #494b4d;
  color: #494b4d;
}
.uk-button:active,
.uk-button.uk-active {
  /*
	background-color: #569aca;
  */
	border-color: #659f13;
	background-color: #659f13;
}

.uk-button-primary {
  /*
	background-color: #569aca;
	*/
	background-color: #101010;
}

.uk-button-primary:hover,
.uk-button-primary:focus {
	/*
  background-color: #62ade3;
	*/
  background-color: #ffef2a;
  border-color: #494b4d;
  color: #494b4d;
}

.uk-button-primary:active,
.uk-button-primary.uk-active {
  /*
	background-color: #4c86b0;
  */
	background-color: #494b4d;
}

.uk-button-link {
  /*
	color: #569aca;
  */
	color: #659f13;
}

.uk-button-link:hover,
.uk-button-link:focus,
.uk-button-link:active,
.uk-button-link.uk-active {
  /*
	color: #417396;
  */
	color: #548510;
}

.uk-icon-button {
  background-color: #101010;
}

.uk-icon-button:hover,
.uk-icon-button:focus {
  /*
	background-color: #569aca;
	*/
  background-color: #ffef2a;
  border-color: #494b4d;
  color: #494b4d;
}

.uk-icon-button:active {
  /*
	background-color: #62ade3;
  */
	background-color: #659f13;
}

.uk-button.uk-disabled {
	background : #9fa1a0;
}




/* Buttons */

/*
.uk-button-primary {
	background-color: #101010;
}

.uk-button-primary:hover,
.uk-button-primary:focus {
  background-color: #ffef2a;
  border-color: #494b4d;
  color: #494b4d;
}

.uk-button-primary:active,
.uk-button-primary.uk-active {
  background-color: #494b4d;
}

.uk-icon-button {
  background-color: #101010;
}

.uk-icon-button:hover,
.uk-icon-button:focus {
  background-color: #ffef2a;
  border-color: #494b4d;
  color: #494b4d;
}

*/






/* Badges */

.uk-badge {
	/*
  background: #569aca;
	*/
	background: #659f13;
}

a.uk-badge:hover {
  /*
	color: #62ade3;
	*/
  color: #659f13;
}

/* Texts */

.uk-text-primary {
	/*
  color: #569aca !important;
  */
	color: #659f13 !important;
}

/* Contrast */

.uk-contrast .uk-button-primary {
  /*
	background-color: #569aca;
  */
	background-color: #659f13;
}

.uk-contrast .uk-button-primary:hover,
.uk-contrast .uk-button-primary:focus {
  /*
	background-color: #62ade3;
  */
	background-color: #74b816;
}

.uk-contrast .uk-button-primary:active,
.uk-contrast .uk-button-primary.uk-active {
  /*
	background-color: #62ade3;
	*/
  background-color: #548510;
}

.uk-contrast .uk-text-primary {
	/*
  color: #569aca !important;
  */
	color: #659f13 !important;
}

/* UI tools */

.uk-progress-bar {
  /*
	background: #569aca;
	*/
  background: #659f13;
}

.uk-nav-autocomplete > li.uk-active > a {
  /*
	background: #569aca;
	*/
  background: #659f13;
}

.uk-datepicker-table a:active {
  /*
	background-color: #569aca;
  */
	background-color: #659f13;
}

.uk-datepicker-table a.uk-active {
  /*
	background: #569aca;
	*/
  background: #659f13;
}

.uk-htmleditor-navbar-nav > li > a:active {
  /*
	background-color: #569aca;
  */
	background-color: #659f13;
}


/* Couleurs des états: */
.state-red, .state-red a, .state-red span {
	color:#ff0000;
}

.state-blue, .state-blue a, .state-blue span {
	color:#0000ff;
}
.state-green, .state-green a, .state-green span {
	color:#00C400;
}
.state-blackbold, .state-blackbold a, .state-blackbold span {
	color:#000000;
	font-weight:bold;
}
.state-orange, .state-orange a, .state-orange span {
	color:#E27C1D;
}
.state-grey, .state-grey a, .state-grey span {
	color:#636D67;
}
.state-black, .state-black a, .state-black span {
	color:#000000;
}

.img_telephone{
	background-image: url('../images/telephone.png')
}