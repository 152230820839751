
/*
 * Backend Layout
 */

/* Wrapper */

.be-wrapper:not(.be-grid-preserve)
{
	margin-left: 0;
}

.be-wrapper:not(.be-grid-preserve) > *
{
	padding-left: 0;
}

.be-wrapper
{
	max-width: 100vw;
	min-height: 100vh;
}

.be-wrapper > div
{
	min-height: 100vh;
	padding-left: 0;
}

/* Header */

.be-header h1
{
	margin-bottom: 0;
}

.be-header h2
{
	color: #569aca;
	font-weight: normal;
}

/* Toolbar */

.be-toolbar .uk-subnav > *
{
	margin-top: 15px;
	line-height: 35px;
}

.be-toolbar .uk-subnav-line > *:nth-child(n+2)::before
{
	border-left: none;
}

.be-toolbar .uk-subnav .be-userinfo
{
	font-style: italic;
}

.be-center{
	text-align: center;
}

/* Sidebar */

.be-sidebar-wrapper::after
{
	background: #ffffff;
	bottom: 0;
	content: "";
	display: block;
	position: absolute;
	right: 0;
	top: 0;
	width: 1px;
}

.be-sidebar-wrapper
{
	background: #ffffff;
	width: 6%;
	position: relative;
	text-align: center;

	li{
		width: 100%;
	}
}

.be-sidebar-wrapper .be-sidebar-menu-container
{
	padding: 30px 0;
	text-align: center;
	z-index: 1002;
}

.be-sidebar-wrapper .be-sidebar-menu-container .be-sidebar-logo
{
	display: inline-block;
	margin: 0 auto 60px;
}

.be-sidebar-wrapper .be-sidebar-menu-container .be-sidebar-nav
{
	margin-bottom: 40px;
}

/* Content */
.be-content-wrapper
{
	width: 100%;
	position: relative;
}

@media screen and (min-width: 960px){
	.be-content-wrapper
	{
		width: calc(100% - 6%) !important;
	}
}

.be-content-wrapper .be-content-container
{
	box-sizing: border-box;
	padding: 15px 30px;
}

/* Main */

.be-main
{
	min-height: calc(100vh - 175px);
	position: relative;
}

/* - Subnav */

.be-subnav .uk-subnav-line > *:nth-child(n+2)::before
{
	border-left: none;
}

.be-subnav a > i
{
	padding-right: 5px;
}

/* - Search and filter */

.be-search-n-filter a > i
{
	padding-right: 5px;
}

/* - Form */

label
{
	font-weight: 600;
}

.be-required label
{
	color: #569aca;
	font-weight: bold;
}

.be-note
{
	font-size: 12px;
	font-style: italic;
}

/* Bottom */

.be-bottom
{
	margin-top: 30px;
}

.be-copyright
{
	font-size: 12px;
}


/* Main subnav menu */

.be-sidebar-subnav
{
	position: absolute;
	background: #569aca;
	display: block;
	top: 0;
	left: 100px;
	width: 250px;
	padding: 40px 30px;
	height: 100vh;
	text-align: left;
	z-index: 2;
}

.be-sidebar-subnav h2
{
	color: #ffffff;
	font-size: 26px;
	padding-bottom: 45px;
	margin-bottom: 15px;
	border-bottom: 1px solid #0a2d45;
}

/* - navbar items */

.be-sidebar-subnav .uk-nav-navbar > li > a
{
	color: #ffffff;
}

.be-sidebar-subnav .uk-nav-navbar > li >  a > i
{
	display: block;
	float: left;
	width: 25px;
	line-height: 20px;
}

.be-sidebar-subnav .uk-nav-navbar > li >  a > span
{
	display: block;
	margin-left: 25px;
	line-height: 20px;
}

/* - Accordion */

.be-sidebar-subnav h3.uk-accordion-title
{
	background: none;
	margin-bottom: 15px;
	margin-top: 15px;
	padding: 0;
	color: #0a2d45;
	font-size: 16px;
	line-height: 30px;
	border: none;
	border-radius: 0;
}

.be-sidebar-subnav .uk-accordion > h3.uk-accordion-title:first-child
{
	margin-top: 0;
}

.be-sidebar-subnav .uk-accordion > h3.uk-accordion-title::after
{
	content: '\f067';
	background: #0a2d45;
	display: block;
	float: right;
	width: 25px;
	height: 25px;
	font-family: 'FontAwesome';
	font-size: 13px;
	color: #ffffff;
	line-height: 25px;
	text-align: center;
	border-radius: 3px;
}

.be-sidebar-subnav .uk-accordion > h3.uk-accordion-title.uk-active::after
{
	content: '\f068';
}

.be-sidebar-subnav .uk-accordion-content
{
	padding: 0;
}


/*
 * UIKit overrides
 */

/* Tabs */

.uk-tab > li > a {
	background: #494b4d;
}

.uk-tab > li.uk-disabled > a {
	background: #d2d4d3;
}

.uk-tab > li.uk-disabled > a:hover,
.uk-tab > li.uk-disabled > a:focus {
	background: #d2d4d3;
}

/* Main navbar */

ul.uk-navbar-nav{
	width: 100%;
}

.uk-navbar-nav:first-child > li:first-child > a {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
}

.uk-navbar-nav > li > a {
	border-width: 0 0 1px 0;
	border-color: #e5e5e5;
	color: #a0a2ab;
	font-family: "Cabin",Helvetica,Arial,sans-serif;
	font-size: 36px;
	font-weight: normal;
	//width: 100px;
	height: 95px;
	line-height: 95px;
	margin-left: 0;
	margin-top: 0;
	padding: 0;
}

.uk-navbar-nav > li:first-child > a {
	border-width: 1px 0 1px 0;
}

.uk-navbar-nav > li:hover > a,
.uk-navbar-nav > li > a.focus,
.uk-navbar-nav > li.uk-open > a,
.uk-navbar-nav > li.uk-active > a {
	background: #569aca;
	color: #ffffff;
}

/* Tables */

.uk-table
{
	margin-top: 15px;
}

.uk-table th
{
	background: #569aca;
	color: #ffffff;
	border-left: 1px solid #ebedec;
}

.uk-table th:first-child
{
	border-radius: 3px 0 0 0;
	border-left: none;
}

.uk-table th:last-child
{
	border-radius: 0 3px;
}

.uk-table .actions td
{
	padding-top: 0;
}

/* Forms */

.uk-form-select
{
	background: #ffffff;
	border: 1px solid #ffffff;
	border-radius: 3px;
	color: #494b4d;
	max-width: 100%;
	line-height: 30px;
}

.uk-form-select select
{
	background: none;
	border: none;
	border-radius: 0;
}

.uk-form-select span
{
	padding: 0 5px;
}

.uk-form-horizontal .uk-width-1-3 .uk-form-label {
	width: 100px !important;
}

.uk-form-horizontal .uk-width-1-3 .uk-form-controls {
	margin-left: 115px !important;
}



/* A TRIER */

/*
.uk-form-icon
{
  margin-left: 15px;
}

.center td, th
{
  text-align: center !important;
}

.valid-buttn{
  display: inline-block;

}

.inline-label {
  display: inline;
  float: none;
  width: auto;
}

.be-nav {
  margin-bottom: 2%;
}

.uk-navbar-nav > li{
  text-align: center;
  width:250px;


}

.uk-navbar-nav > li.uk-active > a {
  background:#ebedec ;

  color: black;

}

.uk-navbar-nav > li > a {
  padding-top: 5%;
  font-size: 22px;
  line-height: 25px;
  width: 100%;
  height: 50px;
  color: black;
}

*/

.ui-autocomplete {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  display: none;
  min-width: 160px;
  padding: 4px 0;
  margin: 0 0 10px 25px;
  list-style: none;
  background-color: #ffffff;
  border-color: #ccc;
  border-color: rgba(0, 0, 0, 0.2);
  border-style: solid;
  border-width: 1px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  *border-right-width: 2px;
  *border-bottom-width: 2px;
}

.ui-menu-item > a.ui-corner-all {
  display: block;
  padding: 3px 15px;
  clear: both;
  font-weight: normal;
  line-height: 18px;
  color: #555555;
  white-space: nowrap;
  text-decoration: none;
}

.ui-state-hover, .ui-state-active {
  color: #ffffff;
  text-decoration: none;
  background-color: #0088cc;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  background-image: none;
}

.filtre_bs, .filtre_bs .uk-form-row{
	font-size:small;
	margin-top: 5px;
}

.block-operator-logo{
	border: 1px solid black;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	margin-bottom: 10px;
}

.select_ope{
	background: -moz-linear-gradient(
					top,
					#99ccff,
					#3399cc
	);
	background: -webkit-gradient(
					linear,
					left top, left bottom,
					from(#99ccff),
					to(#3399cc)
	);
}

.logo_operator{
	margin: 5px;
	width:100px;
	height:100px;
	cursor: pointer;
}

.div_dashboard{
	width: 100%;
	-webkit-appearance: none;
	margin-top: 3px;
	padding-left: 0px;
	border: 1px solid black;;
	box-sizing: border-box;
	vertical-align: middle;
	text-align: center;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	text-shadow: 0 1px 0 none;
	min-height: 100px;
}

.div_chart{
	margin-left: -15px;
	width: 90%;
}

.backColorRed{
	background-color: red;
	color: black;
}

.backColorOrange{
	background-color: orange;
	color: black;
}

.backColorGreen{
	background-color: green;
	color: black;
}

.modalwait {
	display:    none;
	position:   fixed;
	z-index:    1000;
	top:        0;
	left:       0;
	height:     100%;
	width:      100%;
	background: rgba( 255, 255, 255, .8 )
	url('/images/Loading_icon.gif')
	50% 50%
	no-repeat;
}

.print-only{
	display: none;
}

.cluster_content{
	margin-top: 30px;

	.div_dashboard{
		width: 100%;
		-webkit-appearance: none;
		margin-top: 3px;
		border: 1px solid black;
		box-sizing: border-box;
		vertical-align: middle;
		text-align: center;
		border-radius: 3px;
		-webkit-border-radius: 3px;
		-moz-border-radius: 3px;
		text-shadow: 0 1px 0 none;
		min-height: 100px;
		padding: 20px 0;
	}
}

@media screen and (min-width: 1100px) {
	.gaugechartSFR table{
		margin-left: 100px !important;
	}
}

#TableInfos{
	td{
		border: 1px solid #d2d4d3;
		padding: 10px 10px;
	}
}

.radio-width{
	display: inline-block;
}


/* responsive menu */
@media screen and (max-width: 768px) {
	.radio-width{
		width: 100%;
	}

	.be-content-wrapper .be-content-container{
		padding: 15px 15px 15px 25px;
	}

	.be-sidebar-wrapper {
		width: 70px;
	}

	.uk-navbar-nav > li > a {
		width: 70px;
	}

	.uk-button{
		padding: 0 10px;
	}
}

.be-submenu{
	margin-bottom: 10px;
	background-color: #fefefe;
}
.uk-dropdown{
	top: 15px !important;
	width: 100%;
	background-color: #efefef;
}

#edit_paypal{
	.uk-panel-box{
		background: transparent;
	}
	input, select{
		border-color: #ddd ;
	}
	input[type="text"]:disabled{
		background-color: #FFFFFF;
	}
}
